import { ButtonA } from '@components/ButtonA'
import Container from '@components/Container'
import SEO from '@components/SEO'
import StaffComponent from '@components/StaffComponent'
import { css, useTheme } from '@emotion/react'
import { FreeTeckyCodeQuery } from '@graphql-types'
import { graphql, PageProps } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import moment from 'moment'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { FaPlayCircle, FaYoutube } from 'react-icons/fa'

const FreeTeckyCode = ({ data }: PageProps<FreeTeckyCodeQuery>) => {
	const { t } = useTranslation()
	const theme = useTheme()

	return (
		<Container>
			<SEO title={t('layout:tecky_code')} keywords={[]} />
			<div>
				<h1>
					<GatsbyImage
						css={css`
							filter: ${theme.imageFilter};
						`}
						image={data.logo?.childImageSharp?.gatsbyImageData}
						alt={t('free:tecky_code.title')}
					/>
				</h1>
			</div>
			<div
				css={css`
					display: flex;
					flex-direction: column;
					align-items: center;
					margin: 1rem;
				`}>
				<p>
					<ButtonA
						href='https://code.tecky.io'
						target='_blank'
						className='button'>
						{t('free:tecky_code.go_to_tecky_code')}
					</ButtonA>
				</p>
				<h2>{t('free:tecky_code.subtitle1')}</h2>
				<div>
					<GatsbyImage
						image={
							data.screenshot1?.childImageSharp?.gatsbyImageData
						}
						alt={t('free:tecky_code.subtitle1')}
					/>
				</div>
			</div>
			<div
				css={css`
					margin: 1rem;
				`}>
				<Trans i18nKey='free:tecky_code.description'></Trans>
			</div>
			<div
				css={css`
					width: 100%;
					display: flex;
					flex-direction: column;
					align-items: center;
					margin: 1rem;
				`}>
				<div
					css={css`
						margin: 1rem;
						display: flex;
						width: 100%;

						> div {
							flex: 1;
							display: flex;
							flex-direction: column;
							align-items: center;

							.icon {
								display: flex;
								height: 75px;
								width: 75px;
								padding: 10px;
								border-radius: 50%;
								background-color: ${theme.linkColor};
							}
						}
					`}>
					<div>
						<div className='icon'>
							<GatsbyImage
								image={
									data.characters?.childImageSharp
										?.gatsbyImageData
								}
								alt={t('free:tecky_code.traditional_chinese')}
							/>
						</div>
						<h3>{t('free:tecky_code.traditional_chinese')}</h3>
					</div>
					<div>
						<div className='icon'>
							<GatsbyImage
								image={
									data.clock?.childImageSharp?.gatsbyImageData
								}
								alt={t(
									'free:tecky_code.choose_your_time_to_learn'
								)}
							/>
						</div>
						<h3>
							{t('free:tecky_code.choose_your_time_to_learn')}
						</h3>
					</div>
					<div>
						<div className='icon'>
							<GatsbyImage
								image={
									data.free?.childImageSharp?.gatsbyImageData
								}
								alt={t('free:tecky_code.free_of_charge')}
							/>
						</div>
						<h3>{t('free:tecky_code.free_of_charge')}</h3>
					</div>
				</div>
				<p>
					<ButtonA
						href='https://code.tecky.io'
						target='_blank'
						className='button'>
						{t('free:tecky_code.go_to_tecky_code')}
					</ButtonA>
				</p>
			</div>
			<h2>{t('free:tecky_code.developed_by_tecky_academy')}</h2>
			<div
				css={css`
					display: flex;
					flex-wrap: wrap;
				`}>
				{data.staff.nodes.map((instructor, i) => (
					<StaffComponent staff={instructor} key={i} />
				))}
			</div>
			<div>
				<p>
					<ButtonA
						href='https://code.tecky.io'
						target='_blank'
						className='button'>
						{t('free:tecky_code.go_to_tecky_code')}
					</ButtonA>
				</p>
			</div>
		</Container>
	)
}

export default FreeTeckyCode

export const query = graphql`
	query FreeTeckyCode($language: String!) {
		...TranslationFragment
		staff: allCockpitStaffCollection(
			filter: {
				active: { eq: true }
				_lang: { eq: $language }
				code: { eq: true }
			}
			sort: { fields: _o, order: ASC }
		) {
			nodes {
				_id
				name
				description
				title
				specialities
				profileImg {
					path
				}
				available_countries
				profileImgSharp {
					childImageSharp {
						gatsbyImageData(
							width: 250
							height: 250
							placeholder: BLURRED
						)
					}
				}
			}
		}
		logo: file(relativePath: { eq: "code-icons/logo.png" }) {
			childImageSharp {
				gatsbyImageData(width: 500, placeholder: BLURRED)
			}
		}
		screenshot1: file(relativePath: { eq: "code-icons/screenshot1.png" }) {
			childImageSharp {
				gatsbyImageData(width: 500, placeholder: BLURRED)
			}
		}
		screenshot2: file(relativePath: { eq: "code-icons/screenshot2.png" }) {
			childImageSharp {
				gatsbyImageData(width: 500, placeholder: BLURRED)
			}
		}
		characters: file(relativePath: { eq: "code-icons/characters.png" }) {
			childImageSharp {
				gatsbyImageData(
					transformOptions: { fit: CONTAIN }
					backgroundColor: "transparent"
					height: 75
					width: 75
					placeholder: BLURRED
				)
			}
		}
		clock: file(relativePath: { eq: "code-icons/clock.png" }) {
			childImageSharp {
				gatsbyImageData(
					transformOptions: { fit: CONTAIN }
					backgroundColor: "transparent"
					height: 75
					width: 75
					placeholder: BLURRED
				)
			}
		}
		free: file(relativePath: { eq: "code-icons/free.png" }) {
			childImageSharp {
				gatsbyImageData(
					transformOptions: { fit: CONTAIN }
					backgroundColor: "transparent"
					height: 75
					width: 75
					placeholder: BLURRED
				)
			}
		}
	}
`
